<template>
   <router-view></router-view>
</template>

<script setup>
import { onBeforeMount, onMounted, onBeforeUpdate, onUpdated, onBeforeUnmount, onUnmounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const getData = async () => {
   if (localStorage.getItem('token') && localStorage.getItem('login')) {
      store.commit('authModule/authData');
      await store.dispatch('authModule/getPerfil');
      await store.dispatch('permisos/getPermiso');
   }
}
// Función que se ejecuta en la etapa de inicio
onBeforeMount(() => {
  console.log('Inicio del componente');
});

// Función que se ejecuta en la etapa de inicialización
onMounted(() => {
   getData();
  console.log('Inicialización del componente');
});

// Función que se ejecuta en la etapa de montaje
onBeforeUpdate(() => {
  console.log('Antes de la actualización del componente');
});

// Función que se ejecuta en la etapa de actualización
onUpdated(() => {
  console.log('Actualización del componente');
});

// Función que se ejecuta en la etapa de desmontaje
onBeforeUnmount(() => {
  console.log('Antes del desmontaje del componente');
});

// Función que se ejecuta en la etapa de desmontaje
onUnmounted(() => {
  console.log('Desmontaje del componente');
});
</script>

