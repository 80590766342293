import axios from '@/plugins/axios/axios';

const actions = {
  async getPermiso (context) {
    try {
      const { data } = await axios.get('/modulo/permiso/login')
      context.commit('getPermiso',data)
    } catch (error) {
      console.log(error)
    }
  }

}

export default actions
