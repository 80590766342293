/* eslint-disable no-unused-vars */
// plugins/vuetify.js
import '@mdi/font/css/materialdesignicons.css'
import '@/plugins/vuetify/stylePersonalizado.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import ThemeLight from './theme-ligth.json'
import ThemeDark from './theme-dark.json'

export default function installVuetify () {
  const vuetify = createVuetify({
    components,
    directives,
    ssr: true,
    theme: {
      themes: {
        light: { ...ThemeLight },
        dark: { ...ThemeDark }
      }
    }
  })

  return vuetify
}
