import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Index.vue'),
      meta: { requiresAuth: true },
      children: [{
            path: '/',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/solicitudes/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SOLICITUDES' },
          },
          {
            path: '/perfil',
            name: 'perfil',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/perfil/Index.vue'),
            meta: { requiresAuth: true, modulo: 'PERFIL' },
          },
          {
            path: '/usuarios',
            name: 'usuarios',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/usuario/Index.vue'),
            meta: { requiresAuth: true, modulo: 'USUARIOS' },
          },
          {
            path: '/clientes',
            name: 'Clientes',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/cliente/Index.vue'),
            meta: { requiresAuth: true, modulo: 'CLIENTES' },
          },
          {
            path: '/bancos',
            name: 'Mis Bancos',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/generales/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SIN MODULO' },
          },
          {
            path: '/estadoCivil',
            name: 'Estado Civil',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/generales/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SIN MODULO' },
          },
          {
            path: '/tipoContrato',
            name: 'Tipo Contrato',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/generales/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SIN MODULO' },
          },
          {
            path: '/tipoIdentificacion',
            name: 'Tipo Identificación',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/generales/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SIN MODULO' },
          },
          {
            path: '/empresa',
            name: 'Empresa',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/empresa/Index.vue'),
            meta: { requiresAuth: true, modulo: 'EMPRESAS' },
          },
          {
            path: '/roles',
            name: 'Roles',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/roles/Index.vue'),
            meta: { requiresAuth: true, modulo: 'ROLES' },
          },
          {
            path: '/intereses',
            name: 'Intereses',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/intereses/Index.vue'),
            meta: { requiresAuth: true, modulo: 'INTERESES' },
          },
          {
            path: '/tasaNominalAnual',
            name: 'Tasa nominal anual',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/intereses/tasaNominal/Index.vue'),
            meta: { requiresAuth: true, modulo: 'INTERESES' },
          },
          {
            path: '/interesCuotaDiaria',
            name: 'Interés cuota diaria',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/intereses/cuotaDiaria/Index.vue'),
            meta: { requiresAuth: true, modulo: 'INTERESES' },
          },
          {
            path: '/simulador',
            name: 'Simulador de crédito',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/simulador/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SIMULADOR' },
          },
          {
            path: '/solicitudes',
            name: 'Solicitud de crédito',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/solicitudes/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SOLICITUDES' },
          },
          {
            path: '/solicitudes/rechazadas',
            name: 'Solicitud de créditos rechazados',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/solicitudes/SolicitudesRechazadas.vue'),
            meta: { requiresAuth: true, modulo: 'SOLICITUDES' },
          },
          {
            path: '/seguros',
            name: 'Seguros',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/seguro/Index.vue'),
            meta: { requiresAuth: true, modulo: 'SEGUROS' },
          },
          {
            path: '/creditos/aprobados',
            name: 'Créditos aprobados',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/creditos/Aprobados.vue'),
            meta: { requiresAuth: true, modulo: 'CREDITOS' },
          },
          {
            path: '/creditos/finalizados',
            name: 'Créditos finalizados',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/creditos/Finalizados.vue'),
            meta: { requiresAuth: true, modulo: 'CREDITOS' },
          },
          {
            path: '/cuota/:id?',
            name: 'Cuotas del préstamo',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/cuota/Index.vue'),
            meta: { requiresAuth: true, modulo: 'CUOTAS' },
          },
          {
            path: '/meta/usuarios',
            name: 'Meta por usuarios',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/meta/Index.vue'),
            meta: { requiresAuth: true, modulo: 'META' },
          },
          {
            path: '/seguimiento-asesor',
            name: 'Seguimiento asesor',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/seguimiento/Seguimiento-asesor.vue'),
            meta: { requiresAuth: true, modulo: 'SEGUIMIENTO' },
          },
          {
            path: '/seguimiento-empresa',
            name: ' Seguimiento-empresa',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/seguimiento/Seguimiento-empresa.vue'),
            meta: { requiresAuth: true, modulo: 'SEGUIMIENTO' },
          },
          {
            path: '/puntaje-minimo',
            name: 'Puntaje mínimo',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/puntajeminimo/Index.vue'),
            meta: { requiresAuth: true, modulo: 'P-MIN' },
          },
          {
            path: '/mora/empresa',
            name: 'Mora empresa',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/mora/Empresa.vue'),
            meta: { requiresAuth: true, modulo: 'MORA' },
          },
          {
            path: '/mora/empresa/detalle/:id?',
            name: 'Mora-empresa-detalles',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/mora/EmpresaMora.vue'),
            meta: { requiresAuth: true, modulo: 'MORA' },
          },
          {
            path: '/cartera/flujo',
            name: 'Cartera',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/cartera/Index.vue'),
            meta: { requiresAuth: true, modulo: 'CARTERA' },
          },
          {
            path: '/cartera/concepto',
            name: 'Concepto',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/cartera/concepto/Index.vue'),
            meta: { requiresAuth: true, modulo: 'CARTERA' },
          },
          {
            path: '/cartera/resumen-financiero',
            name: 'Resumen-financiero',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/cartera/resumen/Index.vue'),
            meta: { requiresAuth: true, modulo: 'CARTERA' },
          },
          

        ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/Index.vue'),
      meta: { modulo: 'SIN MODULO' },
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/HomeView.vue'),
      meta: { modulo: 'SIN MODULO' },
    },
  ]
})
router.beforeEach(async (to, from, next) => {
  let login = (localStorage.getItem('login') == 'true') ? true : false;
  let token = (localStorage.getItem('token')) ? true : false;
  let autenticado = login && token
  if (to.meta?.requiresAuth && !autenticado) {
    next({
      path: '/login',
      replace: true
    })
  } else if (to.name === 'Login' && autenticado) {
    next({
      path: '/',
      replace: true
    })
  } else {
    next()
  }
})

export default router
