import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import installVuetify from './plugins/vuetify/vuetify'
import axios from './plugins/axios/axios'

const vuetify = installVuetify()
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/primevue.min.css';
// import 'primevue/resources/themes/md-dark-deeppurple/theme.css'
import 'primevue/resources/themes/lara-light-blue/theme.css';
import './assets/style/personalizado.css'

import VueApexCharts from "vue3-apexcharts";

const app = createApp(App)
app.directive('tooltip', Tooltip);
app.config.globalProperties.$axios = axios

app.use(store)
app.use(router)
app.use(vuetify)
app.use(PrimeVue)
app.use(VueApexCharts)
app.mount('#app')
