import axios from 'axios';
import store from '@/store'
import router from '@/router/index';

const { VUE_APP_API } = process.env;

const axiosInstance = axios.create({
  baseURL: VUE_APP_API,
  timeout: 5000
});

axiosInstance.interceptors.request.use( async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.url === '/login') {
    if (config.headers.common && config.headers.common.Authorization) {
      delete config.headers.common.Authorization;
    }
  }
  await router.isReady();
  const { _rawValue: { meta: { modulo } }} = router.currentRoute;
  // Verificar si excluimos la solicitud de la validacion
  const { method,url } = config;
  let peticionesSinValidacion = [
    '/usuario/perfil',
    '/modulo/permiso/login',
    '/bancos',
    '/general/estadocivil',
    '/general/tiposcontratos',
    '/general/tiposidentificacion',
    '/tasanominalActivo']
  let moduloSinValidacion = [
    { ruta: '/empresa', modulo: 'EMPRESAS'},
    { ruta: '/rol', modulo: 'ROLES'}
  ]
  // { ruta: '/tasanominal', modulo: 'TASA NOMINAL'},
  if (peticionesSinValidacion.includes(url)) {
    return config;
  }
  if(moduloSinValidacion.some(x => x.ruta == url && x.modulo != modulo)){
    return config;
  }
  if(url.includes('cliente') && modulo == 'SOLICITUDES') {
    return config;
  }
    try {
      let permisoUsuario = store.getters['permisos/gettersPermisos'];
      if(permisoUsuario.length == 0) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        permisoUsuario = store.getters['permisos/gettersPermisos'];
      }
      const metodoArray = [
          { metodo: 'post', valor: 1},
          { metodo: 'get', valor: 2},
          { metodo: 'put', valor: 3},
          { metodo: 'delete', valor: 4}
      ]
      let permisoId = metodoArray.find(x => x.metodo === method).valor
      let resultPermiso = permisoUsuario.some( x => x.modulo === modulo && x.permiso.includes(permisoId))
      if(modulo !== 'SIN MODULO' && !resultPermiso) throw new Error('Sin permiso')
    } catch (error) {
      return Promise.reject(error);
    }
  return config;
});

export default axiosInstance;
